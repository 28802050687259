import React from "react"
import { graphql } from "gatsby"

import BlogLatest from "../components/blog-latest"
import Services from "../components/services"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Quotes from "../components/quotes"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="We do custom software and mobile add development" />
      <div className="banner-area banner-3">
        <div className="overlay dark-overlay"></div>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 m-auto text-center col-sm-12 col-md-12">
                  <div className="banner-content content-padding">
                    <h5 className="subtitle">A technical agency</h5>
                    <h1 className="banner-title">
                      We develop technical solutions
                    </h1>
                    <p>
                      We provide technical development services &amp; partner
                      with businesses looking to expand their digital offering.
                    </p>

                    <a
                      href="/get-in-touch"
                      className="btn btn-white btn-circled"
                    >
                      ready to talk?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CaseStudy /> */}
      <Services />
      <Quotes />
      {/* <Clients /> */}
      <BlogLatest />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
