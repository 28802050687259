import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const BlogList = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 100)
            fields {
              slug
            }
            frontmatter {
              day: date(formatString: "DD")
              month: date(formatString: "MMM")
              year: date(formatString: "YYYY")
              title
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return (
    <section id="blog" className="section-padding bg-main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 m-auto">
            <div className="section-heading">
              <h4 className="section-title">Our Latest Insights</h4>
              <div className="line"></div>
            </div>
          </div>
        </div>

        <div className="row">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div
                className="col-lg-4 col-sm-6 col-md-4"
                key={node.fields.slug}
              >
                <div className="blog-block ">
                  <img src="images/blog/blog-1.jpg" alt="" className="w-100" />
                  <div className="blog-text">
                    <h6 className="author-name">By Craig Pickles</h6>
                    <Link
                      to={node.fields.slug}
                      className="h5 my-2 d-inline-block"
                    >
                      {title}
                    </Link>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default BlogList
