import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"

const Quotes = () => {
  return (
    <section id="testimonial" className="section-padding bg-main">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <div className="mb-5">
              <h3 className="mb-2">Trusted by our clients</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 m-auto col-sm-12 col-md-12">
            <div className="carousel slide" id="testimonial-carousel" data-interval="5000" data-ride="carousel">
              <div className="carousel-inner">
                <ol className="carousel-indicators">
                  <li
                    data-target="#testimonial-carousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#testimonial-carousel" data-slide-to="1"></li>
                </ol>

                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="testimonial-content style-2">
                        <p className="quote">
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            className="text-brand mr-2"
                          />
                          It was fantastic to collaborate with Invently. Their
                          work is highly professional and their technical
                          experience shined though making light work of the
                          app's complex technical requirements.
                          <FontAwesomeIcon
                            icon={faQuoteRight}
                            className="text-brand ml-2"
                          />
                        </p>
                        <div className="author-text">
                          <h5>Stephen Ball</h5>
                          <p>Cost Advice Services Ltd</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="testimonial-content style-2">
                      <p className="quote">
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            className="text-brand mr-2"
                          />
                          Invently's cutting-edge approach and proficiency in software development and systems integration made them the perfect partners for this project. The solution they delivered has seamlessly streamlined our operations, affording us the freedom to focus on our core priorities.
                          <FontAwesomeIcon
                            icon={faQuoteRight}
                            className="text-brand ml-2"
                          />
                        </p>
                        <div className="author-text">
                          <h5>Nick Evans</h5>
                          <p>Milestone Financial Planning</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="carousel-item ">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="testimonial-content style-2">
                        <div className="author-info ">
                          <div className="author-img">
                            <img
                              src="images/author/5b.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>

                        <p>
                          <i className="icofont icofont-quote-left"></i>They is
                          a great platform to anyone like who want to start
                          buisiness but not get right decision. It’s really
                          great placefor new to start the buisness in righ way!{" "}
                          <i className="icofont icofont-quote-right"></i>
                        </p>
                        <div className="author-text">
                          <h5>Marine Joshi</h5>
                          <p>Senior designer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item ">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="testimonial-content style-2">
                        <div className="author-info ">
                          <div className="author-img">
                            <img
                              src="images/author/3b.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>

                        <p>
                          <i className="icofont icofont-quote-left"></i>They is
                          a great platform to anyone like who want to start
                          buisiness but not get right decision. It’s really
                          great placefor new to start the buisness in righ way!
                          <i className="icofont icofont-quote-right"></i>
                        </p>
                        <div className="author-text">
                          <h5>Marine Joshi</h5>
                          <p>Senior designer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Quotes
