import React from "react"

import MobileIcon from "../assets/icons/mobile.svg"
import StrategyIcon from "../assets/icons/strategy.svg"
import SoftwareIcon from "../assets/icons/software.svg"
import PrototyingIcon from "../assets/icons/prototying.svg"
import CloudIcon from "../assets/icons/cloud.svg"
import ServiceIcon from "../assets/icons/service.svg"

const Services = () => {
  return (
    <div>
      <section id="service-head" className=" bg-feature">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 m-auto">
              <div className="section-heading text-white">
                <h4 className="section-title">Our Services</h4>
                <p>
                  We are full stack software development agency which means
                  we've got you covered on technical strategy, design &amp;
                  software engineering.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6 service-box__wrapper">
              <div className="service-box">
                <div className="service-img-icon">
                  <StrategyIcon className="img-fluid icon" />
                </div>
                <div className="service-inner">
                  <h4>Technical Strategy</h4>
                  <p>
                  Our collaborative technical strategy service involves working closely with businesses to create a tailored plan that incorporates their goals and feedback every step of the way.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 service-box__wrapper">
              <div className="service-box ">
                <div className="service-img-icon">
                  <SoftwareIcon className="img-fluid icon" />
                </div>
                <div className="service-inner">
                  <h4>Web Applications &amp; Software Development</h4>
                  <p>
                  We build customized Web Application and Software Development solutions that are reliable, user-friendly, scalable, and designed to help businesses achieve their goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 service-box__wrapper">
              <div className="service-box">
                <div className="service-img-icon">
                  <MobileIcon className="img-fluid icon" />
                </div>
                <div className="service-inner">
                  <h4>Mobile App Development</h4>
                  <p>
                  Count on our expert team to employ the latest technology and industry best practices for delivering high-quality mobile apps that assist businesses in achieving their goals.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6 service-box__wrapper">
              <div className="service-box">
                <div className="service-img-icon">
                  <PrototyingIcon className="img-fluid icon" />
                </div>
                <div className="service-inner">
                  <h4>Prototying</h4>
                  <p>
                  We utilize cutting-edge tools and technology to deliver high-quality prototypes that accurately reflect our clients' vision and objectives.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 service-box__wrapper">
              <div className="service-box">
                <div className="service-img-icon">
                  <CloudIcon className="img-fluid icon" />
                </div>
                <div className="service-inner">
                  <h4>Cloud Development &amp; Consultancy</h4>
                  <p>
                  We offer secure, reliable, and scalable solutions that utilize the latest cloud technology and industry best practices. 
                  </p> 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 service-box__wrapper">
              <div className="service-box">
                <div className="service-img-icon">
                  <ServiceIcon className="img-fluid icon" />
                </div>
                <div className="service-inner">
                  <h4>Software Development as a Service </h4>
                  <p>
                  We provide a full-service Software Development service to work closely with clients and their teams, delivering customized solutions on-time and within budget.
                  </p> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Services
